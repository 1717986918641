import React, { useEffect, useState } from "react";
import "./Header.css";
import { NavLink } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { app, database, auth } from "../firebaseConfig";
import { useNavigate } from "react-router-dom";

import {
    collection,
    query,
    where,
    onSnapshot,
    documentId
  } from "firebase/firestore";

  // env import
import {environment} from '../environment';
import interactionSound from "../static/Intereact.mp3";
import { useSelector } from "react-redux";
const Sidebar = () => {
    const [audio, setAudio] = useState(new Audio(interactionSound));
    let navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [isOngoing, setisOngoing] = useState(false);
    const [userRoleArray, setUserRoleArray] = useState([]);
  
    useEffect(() => {
        if (loading) return;
        if (!user) navigate("/");
        else {
         
         // get user Role data

          const getUserRole = async () => {
            const q = query(collection(database,environment.REACT_APP_USERS_DB), 
            where('email', '==', user?.email)
            
            );
            onSnapshot(q, (querySnapshot) => {
                let roles =[];
              querySnapshot.forEach((doc) => {
                const id = doc.id;
                const data = doc.data();
                data["id"] = doc.id;
      
                roles.push(data.userType);
              
              
               
              });
              setUserRoleArray(roles)
            });
          }
          getUserRole();
          
        }
      }, [user, loading]);

      const userRoleStore = useSelector((state) => state.userRole);
      const userRole1 = userRoleStore? userRoleStore:localStorage.getItem("userRole");

      //console.log("User Role is::", userRoleArray,userRole1)

     
    return (
        <>

            <ul className="nav nav-pills align-content-start h-100 text-center" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                <li className="nav-item btn-grid sticky-top"  key="1">
                    <NavLink to="/" className="nav-link py-3 rounded-0">
                        <i className="bi bi-grid-3x3-gap-fill"></i>
                    </NavLink>
                </li>
               
                { userRole1 ==="SA"  || userRole1 === "SSA" ? 
                <>
                  <li className="nav-item" key="5">
                    <NavLink to="/lead-listing" className="nav-link py-3 rounded-0" title="View Lead Details">
                    <i class="bi bi-download"></i>
                    </NavLink>
                </li>
               
                </>
                
                
                : null }
                
            </ul>
        </>
    );
};

export default Sidebar;