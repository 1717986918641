import React, { useEffect, useState } from "react";
import { app, database, auth } from "../firebaseConfig";
import "./LeadsListingPage.css";
import {
    collection,
    query,
    where,
    onSnapshot,
    orderBy,
} from "firebase/firestore";
import notificationSound from "../static/Notification.mp3";
import { useSelector } from "react-redux";
import { connect } from "react-redux";
import {
    setIdentity,
    setRoomId,
    setFormId,
    setUserInfo,
} from "../store/actions";

import { Link, useNavigate } from "react-router-dom";

import { useAuthState } from "react-firebase-hooks/auth";


// api call import

import { callAxios, convertReadable, convertReadableTime } from '../utils/utils';

// env import
import {environment} from '../environment';

//datepicker import
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SheelaModal from "../modals/SheelaModal";
import MeetingCard from "../Meetings/DownloadMeetingCard";
import { ChatAssistant } from "../Chatboat/ChatAssistant";

const DownloadMeeting = (props) => {
    const {setLoggedinUser} = props;
    const [isSheelaView, setSheelaView] = useState(false);

    const [user, loading, error] = useAuthState(auth);

    const [startDate, setStartDate] = useState(new Date());
    
    let navigate = useNavigate();

    const [allData, setAllDate] = useState([]);

    const [allClientMeetings,setDownloadData] =useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const [clickedRoomId,setROOMId] =useState("");

    const [roomData,setRoomData] =useState([]);

    const [isLoadingTrans, setIsloadingTrans] = useState(true);
    const [transcriptData, setTranscriptData] = useState([]);
    const [transcriptRoomIds, setTranscriptRoomIds] = useState([]);

    const [allSheelaData, setSheelaLeads] = useState([]);

    const [roomId4Sheela, setRoomIdForSheela] = useState('');

    const [allMeetingRoomIds, setAllMeetingRoomIds] = useState([]);

    const [isMeetingLoaded, setIsMeetingLoaded] = useState(false);


    var startOfToday = new Date();
    startOfToday.setHours(0, 0, 0, 0);
  
    var endOfToday = new Date();
    endOfToday.setHours(23, 59, 59, 999);
   
    const queryConstraints = [];
    queryConstraints.push(where("timeStamp", ">=", startOfToday));
    queryConstraints.push(where("timeStamp", "<=", endOfToday));
    queryConstraints.push(orderBy("timeStamp", "desc"));
    const q = query(
      collection(database, environment.REACT_APP_FIREBASE_SHEELA_LEADS_DB),
      ...queryConstraints
    );

    
   
    useEffect(() => {
        onSnapshot(q, (querySnapshot) => {
          let forms = [];
          let sheelaLeads = [];
          querySnapshot.forEach((doc) => {
            const id = doc.id;
            const data = doc.data();
            data["id"] = doc.id;
            sheelaLeads.push({ ...doc.data(), id: doc.id });
            
            
          });
    
          setTranscriptData(forms);
          setIsloadingTrans(false);
        //   setTranscriptRoomIds(roomArray);
        setSheelaLeads(sheelaLeads)
        });
    
        //audio.play()
      }, []);

   
console.log(allSheelaData.length)
    // useEffect(() => {
    //     if (loading) return;
    //     // if (!user) navigate("/");

    //     if(user){
    //         const userInfo = {
    //             uid: user?.uid,
    //             email: user?.email,
    //             displayName: user?.displayName,
    //             accessToken: user?.accessToken,
    //             idToken: user?.idToken,
    //           };
    //           setLoggedinUser(userInfo);
    //     }
       
    // }, [user, loading]);

    const closePlayer = () => { 
        var videoSrc = document.getElementById("videoSrc");
        videoSrc.pause();
        document.getElementById("video-player").style.display="none";
    }
    const handleMeetingViewAndDownload = async(compositionId, type, roomId) =>{
        //console.log("composition Id:", compositionId, " | type:", type)
        let mediaResponse;
        if(type === 'view'){
           
            mediaResponse = await callAxios(environment.REACT_APP_API_URL_Live+'api/v1/video-media/get-media-url',{compositionSid:compositionId},"post");
            const url = mediaResponse.data.url;
           
            var videoSrc = document.getElementById("videoSrc");

            const isSupp = videoSrc.canPlayType("video/mp4");
            if (isSupp == "") {
                videoSrc.src = url;
            } else {
                videoSrc.src = url;
            }
            videoSrc.load();
            videoSrc.play();

        document.getElementById("video-player").style.display="block";

        }
        else {
            mediaResponse = await callAxios(environment.REACT_APP_API_URL_Live+'api/v1/video-media/download-media',{compositionSid:compositionId},"post");
            const url = mediaResponse.data.url;
            window.location.href = url;
        }

        
        
    }

    const createComposition = async(roomId) =>{
       // console.log(roomId);
       setROOMId(roomId)
        document.getElementById(roomId).classList.remove("hide");
        const response = await callAxios(environment.REACT_APP_API_URL_Live+'api/v1/video-media/create-composition',{roomId:roomId},"post");
        if(response){
            //document.getElementById(roomId).classList.add("hide");
        }
    }
    
    const scrollCss = {
        
        width: "100%",
        height: "100px",
        overflow: "auto"
      }
      
      const removeDuplicates= (connectedClientArray) => {
              
        let freshConnectedLeads = [];
        let roomIds = [];
        let uniqueObject = {};
        for (let i in connectedClientArray) {
    
            const objRoomId = connectedClientArray[i]['roomId'];
            uniqueObject[objRoomId] = connectedClientArray[i];
        }
          
        for (let i in uniqueObject) {
          freshConnectedLeads.push(uniqueObject[i]);
        }
          
        return freshConnectedLeads;
    }

    const getRoomIds= (connectedClientArray) => {
              
       
        let roomIds = [];
        let uniqueObject = {};
        for (let i in connectedClientArray) {
    
            const objRoomId = connectedClientArray[i]['roomId'];
            uniqueObject[objRoomId] = connectedClientArray[i];
        }
          
        for (let i in uniqueObject) {
            roomIds.push(uniqueObject[i].roomId);
        }
          
        return roomIds;
    }

    
    const DownloadPageMeetingRoomIds = getRoomIds(allClientMeetings);
    //console.log(transcriptRoomIds, DownloadPageMeetingRoomIds)

    // const forAPIFire = transcriptRoomIds.filter( function(n) { return !this.has(n) }, new Set(DownloadPageMeetingRoomIds) );

    // console.log(forAPIFire)
    const connectedLeads = removeDuplicates(allClientMeetings);

    // console.log("printArray:::",connectedLeads)
     let vlisting;
    if(allSheelaData.length > 0){
        vlisting = allSheelaData?.map((item, index) => {
        
            const fireBaseTime = new Date(
                item.timeStamp.seconds * 1000 + item.timeStamp.nanoseconds / 1000000
              );
              const dateNew = fireBaseTime.toDateString();
              const atTime = fireBaseTime.toLocaleTimeString();
              let timeStampfor = dateNew + " " + atTime;

        return (
            <div className="info-card rounded-2 mt-3" key={"meeting-download-key-" + index}>
            <div className="info-card-details overflow-auto p-2 p-md-3">
                <div className="row gx-3">
                    <div className="col-6 col-lg-1">
                                               
                    </div>
                    <div className="col-6 d-lg-none">
                       
                    </div>
                    <div className="col-lg-9 mt-2 mt-lg-0">
                        {(item.name) ? <div className="client-name text-capitalize mb-1">{item.name}</div> : ''}
                        {(item.description) ?
                            <div className="requrement-label text-white text-opacity-75 mb-1">Requirement Details</div>
                            :
                            <div className="requrement-label text-danger mb-1">Client didn't fill the requirement yet</div>
                        }
                        {(item.description) ?
                            <div className="client-requrement">
                                {(item.description) ? item.description : null}
                            </div>
                            : null}

                        {(item.phone) ?
                            <div className="client-requrement">
                                {(item.phone) ? item.phone : null}
                            </div>
                            : null}

{(item.email) ?
                            <div className="client-requrement">
                                {(item.email) ? item.email : null}
                            </div>
                            : null}

{(item.country) ?
                            <div className="client-requrement">
                                {(item.country) ? item.country : null}
                            </div>
                            : null}

                        {(item.state) ?
                            <div className="client-requrement">
                                {(item.state) ? item.state : null}
                            </div>
                            : null}

{(item.ip) ?
                            <div className="client-requrement">
                                {(item.ip) ? item.ip : null}
                            </div>
                            : null}

{(item.ppcurl) ?
                            <div className="client-requrement">
                                {(item.ppcurl) ? item.ppcurl : null}
                            </div>
                            : null}

                        <div className="row mt-3 pt-lg-3">
                            <div className="col-lg-3 left-sec d-none d-lg-block">
                                <div className="date-time-state text-white text-opacity-75 mb-1">{item.timeStampfor}</div>
                               
                            </div>
                            <div className="col-lg-9 right-sec">
                                <div className="d-flex align-items-center gap-2">

                                    
                                </div>
                            </div>
                        </div>

                        <div className="btn-sec d-flex flex-wrap gap-2 mt-3">
                           
                        </div>
                    </div>
                    <div className="col-lg-2 mt-2 mt-lg-0 text-lg-end">
                        <div className="img-wrapper position-relative d-flex align-items-end justify-content-center h-100" style={{ bottom: '-1rem' }}>
                        
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )

        // return <MeetingCard item = {item} index={index} percentage={percentage} spinnerId={spinnerId} />
        
        });
    } else{
        // setIsLoading(false);
        vlisting = "No meeting found for View or Download"
    }
   

    useEffect(() => {

// getDownloadData(new Date());
const datePickerDate = document.getElementById("wonDatePicker").value;
//console.log(datePickerDate)
getMeetingData(new Date(datePickerDate));

     }, [roomData]);

const getMeetingData = async(date) =>{
    var startOfToday = new Date(date);
    startOfToday.setHours(0, 0, 0, 0);
  
    var endOfToday = new Date(date);
    endOfToday.setHours(23, 59, 59, 999);
   
    const queryConstraints = [];
    queryConstraints.push(where("timestamp", ">=", startOfToday));
    queryConstraints.push(where("timestamp", "<=", endOfToday));
    queryConstraints.push(orderBy("timestamp", "desc"));
    const q = query(
      collection(database, environment.REACT_APP_FIREBASE_COMPOSITION_DB),
      ...queryConstraints
    );

    onSnapshot(q, (querySnapshot) => {
        let forms = [];
        let roomArray = [];
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          const data = doc.data();
          data["id"] = doc.id;
          forms.push({ ...doc.data(), id: doc.id });
          roomArray.push(doc.id);
          
        });
  
        // setTranscriptData(forms);
        // setIsloadingTrans(false);
        setTranscriptRoomIds(roomArray);
      });
    setStartDate(date)
    const filteredData = [];
callAxios(environment.REACT_APP_API_URL_Live+'api/v1/attendance/get-all-download',{date:date}, "post" ).then((meetingData)=>{
   // console.log("downloadData:::",meetingData.data[0].participants)
   setIsLoading(false);
    if(meetingData.data.length > 0){
        meetingData.data.map((item, index) =>{
                //console.log(index)
            if(item.participants){
                const participants = JSON.parse(item.participants);
                participants.map((p) => {
                
                if (p?.identity.includes("_CL_") || p?.identity.includes("+17862041697-")) {
                    filteredData.push(item);
                    
                    //console.log(item)
                   
                }
            })
         // console.log("Client:::",filteredData)
           
            
           
            }
            
            });

            setDownloadData(filteredData)
            setIsMeetingLoaded(true);
            
        }
        setAllDate(meetingData.data);
    
})
}

const renderMonthContent = (month, shortMonth, longMonth) => {
    const tooltipText = `Tooltip for month: ${longMonth}`;
    return <span title={tooltipText}>{shortMonth}</span>;
  };

  useEffect(() => {
    
   
    const getLeadDetails = async () => {
      if(clickedRoomId.length > 0){
        const q = query(collection(database,environment.REACT_APP_FIREBASE_DB), where("roomId", '==', clickedRoomId));
      onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          const data = doc.data();
          data["id"] = doc.id;
          setRoomData(data);
         
        });
      });
      }
      

      
    }
    getLeadDetails();

    
  }, [clickedRoomId]);
//console.log(roomData)
if(roomData && !roomData.isCompositionCreated){
    const percentageShowing = document.getElementById("percentage-showing-"+roomData.roomId);
    if(percentageShowing){
        percentageShowing.classList.remove("d-none");
        if(roomData.compositionPercentageDone > 0)
        percentageShowing.innerHTML = roomData.compositionPercentageDone + "%";
       
    }
    
}
if(roomData && roomData.isCompositionCreated){
    const meetingLoader =  document.getElementById(roomData.roomId);
    if(meetingLoader)
    meetingLoader.classList.add("hide");
    const compositionBtn = document.getElementById("createComBtn-"+roomData.roomId);
    if(compositionBtn)
    compositionBtn.classList.add("d-none")

    const percentageShowing = document.getElementById("percentage-showing-"+roomData.roomId);
    if(percentageShowing)
    percentageShowing.classList.add("d-none")
}

const analyzeData= (roomId) =>{
    setRoomIdForSheela(roomId)
    //console.log("sheela here")
    document.getElementById("SheelaModal").style.display="block";
    document.getElementById("SheelaModal").classList.add("show");
    setSheelaView(true);
}

const getUnmatchedValues = (arr2, arr1) => {
    //console.log(arr1,arr2)
    return arr1.reduce((unmatched, item) => {
        if (!arr2.includes(item)) {
          unmatched.push(item);
        }
        return unmatched;
      }, []);
  }
// useEffect(()=>{
//     if(transcriptRoomIds){
        
//         // console.log(allClientMeetings)
        
//         if(connectedLeads.length > 0){
          
//             //console.log(transcriptRoomIds,connectedLeads,DownloadPageMeetingRoomIds)
//             const roomIdForTranscription = getUnmatchedValues(transcriptRoomIds,DownloadPageMeetingRoomIds)
//             //console.log(roomIdForTranscription.length,transcriptRoomIds.length)
//             if(transcriptRoomIds.length > 0 && roomIdForTranscription.length > 0 && transcriptRoomIds.length !== 0)
//             //console.log(transcriptRoomIds,roomIdForTranscription)
//            callAxios(environment.REACT_APP_API_URL_Live+'api/v1/video-media/CallAudioCompoistionDownloadMeeting',{roomIds:roomIdForTranscription},"post");
            
//         }
//     }
   
   
// },[transcriptRoomIds,isMeetingLoaded])



    return (
        <>
           
           <div className="compose-media-page">
            <div className="container">
                <div className="live-listing-wrapper py-3">
                    <div className="row align-items-center g-2 mb-2">
                        <div className="col-md-8">
                            <div className="fs-3 fw-semibold text-white text-opacity-75 lh-sm">
                                Leads (Latest knowledge document version 4.0.5)
                            </div>
                        </div>

                        <div className="col-md-4 text-md-end d-none">
                            <label className="small">Filter By Date:</label>
                            <DatePicker
                                // selected={new Date()}
                                selected={startDate}
                                onChange={(date) => { getMeetingData(date) }}
                                className="wonDatePicker mb-2 ms-2"
                                id="wonDatePicker"
                                renderMonthContent={renderMonthContent}
                                showFullMonthYearPicker
                                dateFormat="yyyy-MM-dd"
                            />
                        </div>
                    </div>

                    <div className="meeting-details-page rounded-3">
                        {/* <div className="list-header small fw-semibold text-white rounded-3 p-3 mb-3 d-none d-lg-block"
                            style={{
                                background: "linear-gradient(168.68deg,#0052c9 1.12%,#0a91db)",
                            }}
                        >
                            <div className="row gx-3">
                                <div className="col">Name</div>
                                <div className="col text-lg-center">Client Form Status</div>
                               
                                <div className="col text-lg-end">Action</div>
                            </div>
                        </div> */}

                        {isLoading ?
                            <div className="" id="lead-list-spinner"
                                style={{
                                    position: 'fixed',
                                    margin: '21px 0px 0px',
                                    left: '44%'
                                }}
                            >
                                <div style={{ marginLeft: "12px", float: "right" }} className="lead-list-spinner"></div>{" "}
                                Please wait ....
                            </div> :
                            <div>{vlisting}</div>
                        }
                    </div>
                </div>
            </div>
            <div id="video-player" class="modal" role="dialog">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-body">
                            <video width="400" controls autoplay id="videoSrc">
                                <source src="" type="video/mp4" ></source>
                                Your browser does not support HTML video.
                            </video>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-default" data-dismiss="modal" onClick={closePlayer}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


            <div id="video-player" class="modal" role="dialog">
            <div class="modal-dialog">
          
                <div class="modal-content">
                
                <div class="modal-body">
                <video width="400" controls autoplay id="videoSrc">
                <source src="" type="video/mp4" ></source>
                
                Your browser does not support HTML video.
                </video>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-default" data-dismiss="modal" onClick={closePlayer}>Close</button>
                </div>
                </div>

            </div>
            </div>
            {/* <AbstractSummaryComponent /> */}
            <SheelaModal setSheelaView={setSheelaView} isSheelaView = {isSheelaView} roomId4Sheela={roomId4Sheela}/>

            <ChatAssistant />
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setIdentityAction: (identity) => dispatch(setIdentity(identity)),
        setRoomIdAction: (id) => dispatch(setRoomId(id)),
        setFormIdAction: (id) => dispatch(setFormId(id)),
        setLoggedinUser: (user) => dispatch(setUserInfo(user)),
    };
};

const mapStoreStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(
    mapStoreStateToProps,
    mapDispatchToProps
)(DownloadMeeting);