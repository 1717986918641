import React from 'react'

function ConnectingButton({ createRoomButton = false, buttonText, onClickHandler }) {

  const buttonClass = createRoomButton ? 'create_room_button mt-5' : 'join_room_button mt-5'
  return (
    <>
      <button className={buttonClass} onClick={onClickHandler}>
        {buttonText}
      </button>
      <div className='login-subtitle mt-2'>You can login with your VirtualEmployee ID</div>
    </>
  )
}

export default ConnectingButton