import React from 'react'
import ConnectingButton from './ConnectingButton'
import {useNavigate} from 'react-router-dom'
function ConnectingButtons({userRole}) {

    let navigate = useNavigate()

    const pushToJoinRoomPage = () => {
        navigate('/join-room')
    }

    const pushToJoinRoomPageHost = () => {
        navigate('/join-room?host=true')
    }

    const pushToLeadListing = () => {
        navigate('/lead-listing',{state:{userROLE:userRole}})
    }

  

  return (
    <div className='connecting_buttons_container text-center'>
     {/*    <ConnectingButton buttonText='Join a meeting'
            onClickHandler={pushToJoinRoomPage}
        />
        <ConnectingButton buttonText='Host a meeting' createRoomButton
            onClickHandler={pushToJoinRoomPageHost}
        /> 
        */}
         {/* <ConnectingButton buttonText='Host a meeting' createRoomButton
            onClickHandler={pushToJoinRoomPageHost}
        /> */}

        <ConnectingButton buttonText='Live Leads' createRoomButton
            onClickHandler={pushToLeadListing}
        />
    </div>
  )
}

export default ConnectingButtons