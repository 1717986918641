import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import Header from "./Header/Header";
import Sidebar from "./Header/Sidebar";
// import { AttendanceList } from "./AttendanceList/Attendance";

import  ViewAccount  from "./Header/AccountPage/ViewAccount";
import MemberListing from "./Header/AccountPage/MemberListing";
import DownloadMeeting from './LeadsListingPage/DownloadMeeting';
import { useState } from "react";
import { useEffect } from "react";
import { collection, onSnapshot, query, where,getDocs, updateDoc, doc,documentId, orderBy,limit } from "@firebase/firestore";
import { auth, database, messaging, onMessage } from "./firebaseConfig";
import { environment } from "./environment";
import Unauthorized from "./Unauthorized/Unauthorized";
import { useAuthState } from "react-firebase-hooks/auth";
import ProtectedRoute from "./utils/ProtectedRoute";
import { store } from "./store/store";

import DeletedUsers from "./Header/AccountPage/DeletedUsers";
import BlockedUsers from "./Header/AccountPage/BlockedUsers";

import notificationSound from "./static/callTune.mp3";

import { useMemo } from "react";
import { MessageNotification } from "./Notification/MessageNotification";
import { LogoutModal } from "./modals/LogoutModal";
import { callAxios } from "./utils/utils";
import { update_user_status } from "./utils/twilioConversation";

import { setUserInfo } from "./store/actions";

import { ChatAssistant } from "./Chatboat/ChatAssistant";



const Layout = (props) => {
  ////console.log(props)
  const {setLoggedinUser} = props;
  const [audio, setAudio] = useState(new Audio(notificationSound));
  const [user, loading, error] = useAuthState(auth);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });

  const [callFrom, setCallFrom] = useState("");
   const [isReceived, setIsReceived] = useState(null);
   const [callStatus, setCallStatus] = useState(null);
   const [callTo, setCallTo] = useState("");
   const [callType, setCallType] = useState("");
   const [roomId, setRoomId] = useState("");
   const [allCalls, setAllDate] = useState([]);
   const [docId, setDocId] = useState("");

   const [userRoleArray, setUserRoleArray] = useState([]);

   const [days, setDays] = useState("");
   const [isShowForceLogout, setIsShowForceLogout] = useState(false);

   const [loginDateTime, setLoginDateTime] = useState('');
  

   var startOfToday = new Date();
   startOfToday.setHours(0, 0, 0, 0);
 
   var endOfToday = new Date();
   endOfToday.setHours(23, 59, 59, 999);
 
 
   const queryConstraints = [];
   queryConstraints.push(where("timeStamp", ">=", startOfToday));
   queryConstraints.push(where("timeStamp", "<=", endOfToday));
   const q = query(
     collection(database, environment.REACT_APP_FIREBASE_DB_CALLS),
     ...queryConstraints
   );

   const updateCallStatus = async (formID) => {
    ////console.log(formID)
    const formRef = doc(database, environment.REACT_APP_FIREBASE_DB_CALLS, formID);
    await updateDoc(formRef, {
      callStatus: "no-response",
    }).then((res)=>{
     ////console.log("updateing localStorage")
     localStorage.setItem("InitiatedCallTime","");
     localStorage.setItem("callType","");
     localStorage.setItem("callDocId","");
     localStorage.setItem("callReceived","");
     
     
    })
  };

  const updateGroupCallStatus = async (formID) => {
    ////console.log("In get Document::",formID)
    const loggedInUser = props?.userInfo?.email? props?.userInfo?.email : localStorage.getItem("userEmail");

    const q = query(collection(database,environment.REACT_APP_FIREBASE_DB_CALLS), where(documentId(), '==', formID));
    onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach((docData) => {
       // //console.log(docData.data())
        const participants = [];
          docData.data().participants.forEach((participant)=>{
             if(participant.identity === loggedInUser ){
               participants.push({status:"no-response",identity:participant.identity});
                           
             }
             else{
               participants.push(participant)
              
             }
          })
          //console.log(participants)
          const formRef = doc(database, environment.REACT_APP_FIREBASE_DB_CALLS, docData.id); 
          updateDoc(formRef, {
            // callStatus: "completed",
            participants: participants
            }).then(() => {
             
              //console.log("group no response updated");
              localStorage.setItem("InitiatedCallTime","");
              localStorage.setItem("callType","");
              localStorage.setItem("callDocId","");
              localStorage.setItem("callReceived","");
          })
          .catch((error) => {
              //console.log(error);
              
          })
      })

    })

    // const formData = collection(database, environment.REACT_APP_FIREBASE_DB_CALLS).doc(formID);
    // const querySnapshot = await getDocs(formData);
    //   //console.log(querySnapshot)
    //   querySnapshot.forEach(async (docData) => {
    //       //console.log(docData.id, ' => ', docData.data());
          // const participants = [];
          // docData.data().participants.forEach((participant)=>{
          //    if(participant.identity === loggedInUser ){
          //      participants.push({status:"no-response",identity:participant.identity});
                           
          //    }
          //    else{
          //      participants.push(participant)
              
          //    }
          // })
          // //console.log(participants)
          // const formRef = doc(database, environment.REACT_APP_FIREBASE_DB_CALLS, docData.id); 
          // updateDoc(formRef, {
          //   // callStatus: "completed",
          //   participants: participants
          //   }).then(() => {
             
          //     //console.log("group no response updated");
          //     localStorage.setItem("InitiatedCallTime","");
          //     localStorage.setItem("callType","");
          //     localStorage.setItem("callDocId","");
          //     localStorage.setItem("callReceived","");
          // })
          // .catch((error) => {
          //     //console.log(error);
              
          // })
  
         
          
      // });
  };

  const updateCallStatusCancelled = async (formID) => {
    ////console.log(formID)
    const formRef = doc(database, environment.REACT_APP_FIREBASE_DB_CALLS, formID);
    await updateDoc(formRef, {
      callStatus: "cancelled",
    }).then((res)=>{
      stopSound(); 
     ////console.log("updateing localStorage")
     localStorage.setItem("InitiatedCallTime","");
     localStorage.setItem("callType","");
     localStorage.setItem("callDocId","");
     localStorage.setItem("callReceived","");
     
     
    })
  };


  const diff_minutes = (currentTime, formTime) => {
    const dt2 = new Date(currentTime);
    const dt1 = new Date(formTime);
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  };

   const checkAudioVideoCall  = () =>{
    onSnapshot(q, (querySnapshot) => {
      let forms = [];
     // if(user){
        querySnapshot.forEach(async(docu) => {
          //////console.log(docu.data().callStatus)
          const id = docu.id;
          const data = docu.data();
          data["id"] = docu.id;
          forms.push({ ...docu.data(), id: docu.id });
          const loggedInUser = props?.userInfo?.email? props?.userInfo?.email : localStorage.getItem("userEmail");
           // //////console.log(loggedInUser, data.isReceived)
            
           if(data.callStatus === "initiated" && data.callType === "private" && (loggedInUser === data.callToEmail || loggedInUser === data.callFromEmail)){
            setCallTo(docu.data().callToName);
            setCallFrom(docu.data().callFromName);
            setRoomId(docu.data().roomId);
            setDocId(docu.id); 
            setCallType(docu.data().callType);

            if(loggedInUser === data.callToEmail){

              const fireBaseTime = new Date(
                data.timeStamp.seconds * 1000 + data.timeStamp.nanoseconds / 1000000
              );
              if(localStorage.getItem("callDocId") === ""){
                localStorage.setItem("InitiatedCallTime", fireBaseTime);
                localStorage.setItem("callType",data.callType);
              localStorage.setItem("callDocId", docu.id);
              localStorage.setItem("callReceived","");
              }
              

            }
            
           
            playSound(); 
            if(loggedInUser === data.callToEmail){
              const openCallModal = document.getElementById("audiVideoCall");
              if(openCallModal){
                openCallModal.classList.remove("d-none");
              }
             }
            }
           
            if(data.callStatus === "initiated" && data.callType === "group" && (data.callGroupEmails.includes(loggedInUser) || loggedInUser === data.callFromEmail)){
              setCallTo(docu.data().callToName);
            setCallFrom(docu.data().callFromName);
            setRoomId(docu.data().roomId);
            setDocId(docu.id); 
            setCallType(docu.data().callType);
            playSound();
            if(data.callGroupEmails.includes(loggedInUser)){
             
              const openCallModal = document.getElementById("audiVideoCall");
              if(openCallModal){
                openCallModal.classList.remove("d-none");
              }
                 const fireBaseTime = new Date(
                data.timeStamp.seconds * 1000 + data.timeStamp.nanoseconds / 1000000
              );
              if(localStorage.getItem("callDocId") === ""){
                localStorage.setItem("InitiatedCallTime", fireBaseTime);
                localStorage.setItem("callType",data.callType);
                localStorage.setItem("callDocId", docu.id);
                localStorage.setItem("callReceived","");
              }
            }
            
            data.participants.forEach(async(participant)=>{
              // if(participant.status === "leaved"){
              //   leaveCount +=1;
              // }

              

              if(participant.identity === loggedInUser && participant.status === "rejected" && participant.identity !== data.callFromEmail){
                stopSound();
                document.getElementById("audiVideoCall").classList.add("d-none");
                const openCallModal = document.getElementById("audiVideoCall");
              if(openCallModal){
                openCallModal.classList.add("d-none");
              }
                 const fireBaseTime = new Date(
                data.timeStamp.seconds * 1000 + data.timeStamp.nanoseconds / 1000000
              );
              if(localStorage.getItem("callDocId") === ""){
                localStorage.setItem("InitiatedCallTime", fireBaseTime);
                localStorage.setItem("callType",data.callType);
                localStorage.setItem("callDocId", docu.id);
                localStorage.setItem("callReceived","");
              }
              } 

              if(participant.identity === loggedInUser && participant.status !== "initiated" && participant.identity !== data.callFromEmail){
                stopSound();
                document.getElementById("audiVideoCall").classList.add("d-none");
               
              } 
            
           })
            
          
            const fireBaseTime = new Date(
              data.timeStamp.seconds * 1000 + data.timeStamp.nanoseconds / 1000000
            );
            const initiatedTime = diff_minutes(
              new Date().getTime(),
              fireBaseTime.getTime()
            );
              //console.log(initiatedTime)
            if (initiatedTime > 0) {
              //console.log("Call cancelled")
              updateCallStatusCancelled(docu.id);
            }
           
            }


           if(data.callStatus === "in-progress" && data.callType === "private" && (loggedInUser === data.callToEmail || loggedInUser === data.callFromEmail)){
            setCallStatus(data.callStatus);
            stopSound(); 
            
           }

           if(data.callStatus === "in-progress" && data.callType === "group" && (data.callGroupEmails.includes(loggedInUser) || loggedInUser === data.callFromEmail)){
            // setCallStatus(data.callStatus);
            // stopSound(); 
            
            data.participants.forEach((participant)=>{

              if(participant.identity === loggedInUser && participant.status === "initiated" && participant.identity !== data.callFromEmail){
                playSound();
                const openCallModal = document.getElementById("audiVideoCall");
                if(openCallModal){
                  openCallModal.classList.remove("d-none");
                }
                
              }
              if(participant.identity === loggedInUser && participant.status === "joined" && participant.identity !== data.callFromEmail){
                
                const openCallModal = document.getElementById("audiVideoCall");
                if(openCallModal){
                document.getElementById("audiVideoCall").classList.add("d-none");
                }
               
              }
           })
            
           }

          

           if(data.callStatus === "rejected" && data.callType === "private" && (loggedInUser === data.callToEmail || loggedInUser === data.callFromEmail)){
           //////console.log("In rejection section. stop ring")
            stopSound(); 
            
           }

           if(data.callStatus === "rejected" && data.callType === "group" && (data.callGroupEmails.includes(loggedInUser) || loggedInUser === data.callFromEmail)){
            //////console.log("In rejection section. stop ring")
             stopSound(); 
             
            }

           if (data.callStatus === 'completed' && data.callType === "group" &&  (loggedInUser === data.callFromEmail || data.callGroupEmails.includes(loggedInUser))) {
            stopSound(); 
            if(data.callGroupEmails.includes(loggedInUser)){
              localStorage.setItem("InitiatedCallTime","");
              localStorage.setItem("callType","");
              localStorage.setItem("callDocId","");
              localStorage.setItem("callReceived","");
              const openCallModal = document.getElementById("audiVideoCall");
              if(openCallModal){
                openCallModal.classList.add("d-none");
              }
              stopSound(); 
             }
            
           
           
          }

          if (data.callStatus === 'completed' && data.callType === "private" &&  (loggedInUser === data.callFromEmail || loggedInUser === data.callToEmail)) {
            stopSound(); 
            if(loggedInUser === data.callToEmail){
              localStorage.setItem("InitiatedCallTime","");
              localStorage.setItem("callType","");
              localStorage.setItem("callDocId","");
              localStorage.setItem("callReceived","");
              const openCallModal = document.getElementById("audiVideoCall");
              if(openCallModal){
                openCallModal.classList.add("d-none");
              }
              stopSound(); 
             }
            
           
           
          }
           

           if(data.callStatus === "no-response" && data.callType === "private" && (loggedInUser === data.callToEmail || loggedInUser === data.callFromEmail)){
            //////console.log("In no response section. stop ring")
            stopSound(); 
            
           }

           if(data.callStatus === "no-response" && data.callType === "group" && (data.callGroupEmails.includes(loggedInUser) || loggedInUser === data.callFromEmail)){
            //////console.log("In no response section. stop ring")
            stopSound(); 
            
           }

          
                  
          
        });
        setAllDate(forms);
       
      //}
     
    
    });
   }

   useEffect(()=>{
    
    checkAudioVideoCall();

  },[])



// ////console.log(localStorage.getItem("InitiatedCallTime"))



setTimeout(() => {
  
  ////console.log("executing in 1 min", callStatus)
  
  if(localStorage.getItem("InitiatedCallTime") !=="" && localStorage.getItem("callType") === "private"){
  
    const initiateTime= new Date(localStorage.getItem("InitiatedCallTime"));
    const docId  = localStorage.getItem("callDocId");
    const callReceived  = localStorage.getItem("callReceived");
    const initiatedTime = diff_minutes(
      new Date().getTime(),
      initiateTime.getTime()
    );
      //console.log(initiatedTime)
    if (initiatedTime > 0 && callReceived === "") {
      //console.log("in initiated time section")
      updateCallStatus(docId);
    }
  } else{
    const initiateTime= new Date(localStorage.getItem("InitiatedCallTime"));
    const docId  = localStorage.getItem("callDocId");
    const callReceived  = localStorage.getItem("callReceived");
    const initiatedTime = diff_minutes(
      new Date().getTime(),
      initiateTime.getTime()
    );
      ////console.log(initiatedTime)
    if (initiatedTime > 0 && callReceived === "") {
      //console.log("in initiated time section")
      updateGroupCallStatus(docId);
    }
  }
  
  },60000)

 const playSound = () =>{
  audio.play();
  audio.addEventListener('ended', function () {
      setTimeout(() => {
        audio.play();
      }, 1000);
      
      }, false);
}

const stopSound = () =>{
audio.pause();

}
 
  
    onMessage(messaging,(payload)=>{
      ////console.log("---->",payload)
      if(payload.data.notificationType !== "call"){
        setNotification({
          title: payload.notification.title,
          body: payload.notification.body,
        });
        setShow(true);
      }
      
    })
   
 
  // //////console.log(user)
   const [userRole, setUserRole] = useState({});
   const pageUrl = window.location.pathname;
   useEffect(() => {
     const getUserRole = async () => {
       ////////console.log("getting role")
      
         const q = query(
           collection(database, environment.REACT_APP_USERS_DB),
           where("email", "==", user?.email),
           orderBy("timeStamp","asc"),
        limit(1)
         );
         onSnapshot(q, (querySnapshot) => {
           querySnapshot.forEach((doc) => {
             const id = doc.id;
             const data = doc.data();
             data["id"] = doc.id;
             setUserRole(data);
             //store.dispatch(setUserRole(data));
             localStorage.setItem("userRole", data?.userType);
       
           
           });
           

         });

         
       
     };
     if(user?.email){
      getUserRole();
     }
     if(user?.email){
    // //console.log("force logout handler is calling...")
    //  const lastLogin = async() =>{
      callAxios(
        environment.REACT_APP_API_URL_Live + "api/v1/attendance/get-last-login",
        {
          email: user?.email
        },
        "post"
      ).then((response)=>{
        if(response.data.lastLogin){
         ////console.log(response.data.lastLogin)
          const logoutTime = response.data.lastLogin.log_out?response.data.lastLogin.log_out:new Date();
          const totalLoggedinTime = diff_hours(
            new Date(logoutTime).getTime(),
             new Date(response.data.lastLogin.log_in).getTime()
           );
          ////console.log("total logged Time::",Math.round(totalLoggedinTime))
           setDays(totalLoggedinTime);
          
        if(Math.round(totalLoggedinTime) > 10){
       
          const loginDate = new Date(response.data.lastLogin.log_in).toISOString().split("T")[0];
         
          const loginTime = new Date(response.data.lastLogin.log_in).toISOString().split("T")[1].split(".")[0];
       
       
          setIsShowForceLogout(true);
          setLoginDateTime(loginDate +' '+ loginTime);
          //alert("Its time to logout!");
          const logoutModel = document.getElementById("forceLlogout");
          if(logoutModel){
          
            logoutModel.classList.add("show");
            logoutModel.style.display = "block";
          }
    
        }
      }
      }).catch((err)=>{
        //console.log(err)
      })

       
     }
   }, [user,pageUrl]);

   
 
   ////console.log(pageUrl)
   const diff_hours = (currentTime, lastLogginTime) => {
     const dt2 = new Date(currentTime);
     const dt1 = new Date(lastLogginTime);
    
     return Math.abs(dt2 - dt1) / 36e5;
   };
     useEffect(()=>{
      //  //console.log("force logout handler is calling...")
      //  if(user && userRole){
 
      //    //console.log(userRole.name,userRole.loggedInTime)
      //    const lastLoggedInTime = new Date(
      //      userRole?.loggedInTime.seconds * 1000 + userRole?.loggedInTime.nanoseconds / 1000000
      //    );
      //    const totalLoggedinTime = diff_hours(
      //      new Date().getTime(),
      //      lastLoggedInTime.getTime()
      //    );
     
      //    //console.log("Total hours from last login::",Math.round(totalLoggedinTime))
      //    if(totalLoggedinTime > 10){
      //      alert("Its time to logout!");
      //      const logoutModel = document.getElementById("forceLlogout");
      //      if(logoutModel){
      //        logoutModel.classList.add("show");
      //        logoutModel.style.display = "block";
      //      }
     
      //    }
      //  }
      
     },[pageUrl,user])
   ////////console.log("from layout.js::::", props.userInfo)
   //console.log(allCalls)
   const [isOnScreen, setIsOnScreen] = useState(true);
   document.addEventListener('visibilitychange', function() {
    if(document.hidden)
        {
            // //console.log("In another tab chat")
            setIsOnScreen(false)

       
        }
    else
        {
            // //console.log("In chat tab screen")
            setIsOnScreen(true)
               
        }
});

useEffect(()=>{
  const loggedInUser = props?.userInfo?.email? props?.userInfo?.email : localStorage.getItem("userEmail");
    update_user_status(loggedInUser,isOnScreen)
},[isOnScreen])


useEffect(() => {
  const getUserRole = async () => {
       const q = query(
        collection(database, environment.REACT_APP_USERS_DB),
        where("email", "==", props?.userInfo?.email)
      );
      onSnapshot(q, (querySnapshot) => {
          let roles = [];
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          const data = doc.data();
          data["id"] = doc.id;
          roles.push(data.userType);
          
        });
        
        setUserRoleArray(roles);
      });

      
    
  };

  getUserRole();
 
}, [props?.userInfo]);
  return (
     
      <div className="main-wrapper">
        
        <div className="sidebar-wrapper border-end border-black">
          <Sidebar />
        </div>
        <div className="main-content">
        <Header user={props?.userInfo} />
          <Routes>







<Route path="/lead-listing" element={
              <DownloadMeeting />
              
              }/>
<Route path="/account-details" element={
              <ProtectedRoute page={"accountdetails"} userRole={userRole?.userType} email={props?.userInfo?.email} userRoleArray={userRoleArray}>
                <ViewAccount />
              </ProtectedRoute>
              }/>



               <Route path="/deleted-member" element={
              <ProtectedRoute page={"deletedUsers"} userRole={userRole?.userType} email={props?.userInfo?.email} userRoleArray={userRoleArray}>
                <DeletedUsers />
              </ProtectedRoute>
              }/> 

               <Route path="/blocked-member" element={
              <ProtectedRoute page={"blockedUsers"} userRole={userRole?.userType} email={props?.userInfo?.email} userRoleArray={userRoleArray}>
                <BlockedUsers />
              </ProtectedRoute>
              }/> 

              

              
                
               
               


                
               

            <Route path="/unauthorized"  element={<Unauthorized userRole={userRole?.userType} /> } />
          </Routes>
        </div>
       
        {/* {useMemo(()=>( */}
        <MessageNotification  notification={notification} isShow={show}/>
      {/* ),[notification])} */}
      {isShowForceLogout?
      <LogoutModal days={Math.round(days)} lastLogin={loginDateTime}/>
      :null}

        {/* Sheela Assistant Card */}
      <ChatAssistant />
     
      </div>
  
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
      setLoggedinUser: (user) => dispatch(setUserInfo(user))
  };
};

const mapStoreStateToProps = (state) => {
  return {
    userInfo: state.userInfo,
  };
};

export default connect(mapStoreStateToProps,
  mapDispatchToProps)(Layout);
