import React, { useEffect, useState } from "react";
import logo from "../resources/images/logo.png";
import ConnectingButtons from "./ConnectingButtons";
import { connect } from "react-redux";

import "./IntroductionPage.css";
import { setIsRoomHost, setLogin, setUserInfo, setUserRole, setToken } from "../store/actions";
import {
  provider,
  getAuth,
  OAuthProvider,
  signInWithPopup,
  app,
  database,
} from "../firebaseConfig";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  getDocs,
  query,
  where,
  onSnapshot,
  orderBy,
  limit,
} from "firebase/firestore";
import ConnectingButton from "./ConnectingButton";
import { useNavigate } from "react-router-dom";

import {callAxios} from "../utils/utils";

import {environment} from '../environment';

import Axios from 'axios'

import { getMessaging, getToken } from "firebase/messaging";
import { update_user_status } from "../utils/twilioConversation";

function IntroductionPage({
  setIsRoomHostAction,
  setLoginAction,
  setLoggedinUser,
  isLoggedIn,
  userInfo,
  userRole,
  setUserRoleAction,
  setTokenAction
}) {
  let navigate = useNavigate();
  //console.log(userRole)
  useEffect(() => {
    if (isLoggedIn && userInfo) navigate("/lead-listing");
    setIsRoomHostAction(false);
  }, []);
  //Save User Ligin Info

  const getUserRole = async (email) => {
    //console.log("getting role")
   
      const q = query(
        collection(database, environment.REACT_APP_USERS_DB),
        where("email", "==", email),
        orderBy("timeStamp","asc"),
        limit(1)
      );
      onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const id = doc.id;
          const data = doc.data();
          data["id"] = doc.id;
          //setUserRole(data);
          setUserRoleAction(data?.userType);
          localStorage.setItem("userRole", data?.userType);
        });
      });
    
  };

  const saveUserLogged = async (userInfo) => {
    // Add a new document in collection "cities"
    setDoc(doc(database, "userData", userInfo?.uid), {
      uid: userInfo?.uid,
      name: userInfo?.displayName,
      email: userInfo?.email,
      accessToken: userInfo?.accessToken,
      isLoggedIn: true,
    })
      .then(() => {
        console.log("Data Sent");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLogin = () => {
    let profileImage;
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then(async(result) => {
        //console.log("Logged Reaults::",result)
        // User is signed in.
       // localStorage.setItem("camJoinedLead", false);
       // localStorage.setItem("bdmJoinedLead", false);
        const credential = OAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        const idToken = credential.idToken;
        auth.currentUser.getIdToken()
        .then(function(idToken) {
          setTokenAction(idToken)
          localStorage.setItem("idToken",idToken);
          localStorage.setItem("userName",result?.user?.displayName);
          localStorage.setItem("userEmail",result?.user?.email);
          localStorage.setItem("camJoinedLead", null);
          localStorage.setItem("bdmJoinedLead", null);
          localStorage.setItem("checkAudio", null);
        //console.log("IDTOKEN::::::",idToken)
        }).catch(function(error) {
        
        });

      var request = new XMLHttpRequest;
      request.open("GET", "https://graph.microsoft.com/v1.0/me/photo/$value");
      request.setRequestHeader("Authorization", "Bearer " + accessToken);
      request.responseType = "blob";
      request.onload = function () {
          if (request.readyState === 4 && request.status === 200) {
              var imageElm = document.createElement("img");
              var reader = new FileReader();
              reader.onload = function () {
                  // Add the base64 image to the src attribute
                  imageElm.src = reader.result;
                  // Display the user's profile picture
                  profileImage = reader.result;
                  //console.log(profileImage);
              }
              reader.readAsDataURL(request.response);
          }
      };
      request.send(null);

        const userInfo = {
          uid: result?.user?.uid,
          email: result?.user?.email,
          displayName: result?.user?.displayName,
          accessToken: accessToken,
          idToken: idToken,
        };
        getUserRole(result?.user?.email);
        // console.log("In token creattion")
         
        // console.log('Requesting permission...');
        // Notification.requestPermission().then((permission) => {
        //   console.log(permission)
        //   if (permission === 'granted') {
        //     console.log('Notification permission granted.');
        //     const messaging = getMessaging();
        //     getToken(messaging, { vapidKey: 'BPR7B9Y-Z2hLPNRQ01VcnOBLymZwyrQ_ScAVVwd3S4y0TeRp30xDHlpb4zx3eqM9HJuJt_RPdj9Xn0hVIh4Xg6U' }).then(async(currentToken) => {
        //       if (currentToken) {
        //         await callAxios(environment.REACT_APP_API_URL_Live+'api/v1/users/update-fcm-token',{email:userInfo.email,token:currentToken}, "post" );
        //        console.log(currentToken)
        //       } else {
        //         // Show permission request UI
        //         console.log('No registration token available. Request permission to generate one.');
        //         // ...
        //       }
        //     }).catch((err) => {
        //       console.log('An error occurred while retrieving token. ', err);
        //       // ...
        //     });
        //   } else {
        //     console.log('Unable to get permission to notify.');
        //   }
        //    });
       // console.log(profileImage)
        setUserInfo(userInfo);
        await callAxios(environment.REACT_APP_API_URL_Live+'api/v1/users/login',{uid:userInfo.uid,email:userInfo.email,display_name:userInfo.displayName}, "post" );
        await callAxios(environment.REACT_APP_API_URL_Live+'api/v1/attendance/login-logout',{uid:userInfo.uid, action: 'Check-In', email:userInfo.email,display_name:userInfo.displayName, profileImage:profileImage}, "post" );
        update_user_status(userInfo.email,true)
        navigate("/lead-listing");
  
        // saveUserLogged(userInfo);
        setLoginAction(true);
        setLoggedinUser(userInfo);
      })
      .catch((error) => {
        setLoginAction(false);
      });
    // [END auth_msft_signin_popup_modular]
  };
  // console.log("TWL");
  // console.log(process.env.REACT_APP_TWILIO_USERNAME);
  return (
    <div className="introduction_page_container py-5">
      <div className="container-fluid px-md-5">
        <div className="row align-items-center">
          <div className="col-md-6 col-lg-7 col-xxl-8 mb-4 mb-lg-0">
            <div className="text-center">
              <h1 className="text-capitalize text-white">
                Welcome To NM-Live Calls
              </h1>
            </div>
          </div>
          <div className="col-md-6 col-lg-5 col-xxl-4">
            <div className="introduction_page_panel mx-auto bg-white rounded-5 overflow-hidden w-100">
              <div className="introduction-body d-flex flex-wrap align-content-center justify-content-center px-4 py-5">
                <div className="logo-img-cover text-center">
                  <img
                    alt="logo"
                    src={logo}
                    className="introduction_page_image"
                  />
                </div>
                <div className="introduction-subtitle mt-3 fw-semibold text-center">
                  VirtualEmployee's client meeting system
                </div>
                {isLoggedIn ? (
                  <ConnectingButtons userRole={userRole} />
                ) : (
                  <ConnectingButton
                    buttonText="Login with Microsoft"
                    createRoomButton
                    onClickHandler={getLogin}
                    userRole={null}
                  />
                )}
              </div>
              <div className="introduction-footer fw-semibold text-capitalize text-center">
                Only for VirtualEmployee's Employees
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStoreStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setIsRoomHostAction: (isRoomHost) => dispatch(setIsRoomHost(isRoomHost)),
    setLoginAction: (isLogin) => dispatch(setLogin(isLogin)),
    setUserRoleAction: (userRole) => dispatch(setUserRole(userRole)),
    setTokenAction: (token) => dispatch(setToken(token)),
  };
};

export default connect(
  mapStoreStateToProps,
  mapDispatchToProps
)(IntroductionPage);
