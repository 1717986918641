import axios from "axios";
import React, { useState } from "react"
import './Chat.css';
import $ from "jquery";

import { v4 as uuidv4 } from "uuid";

export const ChatAssistant = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openChatAssistant = () => {
    setIsOpen(current => !current);
  };

  const [prompt, setPrompt] = useState("");

  const [response, setResponse] = useState("");

const uuid = uuidv4();

async function getusrIP() {
  const ipData = await axios.get('https://api.ipify.org?format=json');
  // console.log(ipData)
  return ipData.data.ip;
}

/*
* Chat Boat Code
*/



let userIdForChat = '';
  if(localStorage.getItem("userId")){
    userIdForChat = localStorage.getItem("userId");
  } else{
    localStorage.setItem("userId",uuid);
    userIdForChat = uuid;
  }

  // const HTTP = 'http://localhost:7000/api/chat';

  const HTTP = 'https://dev.virtualemployee.com/sheela-ai/api/chat';

  const handleSubmit = async(e) =>{
    e.preventDefault();
    const visitorIp = await getusrIP();
    const visitedPage = window.location.href;
    const message = document.getElementById("userQuery").value;

    const appendedMessage = message + ` Don't justify your answers" and "Don't give information not mentioned in the context information`;

    if(message.length > 0){
      $("#userQuery").val("");
      // console.log(message)
      $("#message-container").append(`<div class="msg_outside text-end mt-2 mb-1"><div class="msg_text text-dark d-inline-block rounded-1 shadow-sm ms-auto p-2">${message}</div></div>

      <div class="msg_myside text-start mt-2 mb-1 message waiting">
      
      <div class="msg_text bg-white text-dark d-inline-block rounded-1 shadow-sm p-2">
      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
      </div>
             </div>
      `);
      
    }
   
    axios
      .post(`${HTTP}`,{ message: JSON.stringify(appendedMessage),userId:userIdForChat,visitedPage:visitedPage,userIp:visitorIp})
      .then((res)=>{
        $(".waiting").remove();
        const formattedMessage = res.data.message.replace(/```(.*?)```/gs, "<pre><code>$1</code></pre>");
        $("#message-container").append(`<div class="msg_myside text-start mt-2 mb-1 message">
      
      <div class="msg_text bg-white text-dark d-inline-block rounded-1 shadow-sm p-2">${formattedMessage}</div>
             </div>
      `);
      $("#message-container").animate({
        scrollTop: $("#message-container").prop('scrollHeight')
      },1000);
      })
      .catch((error)=>{
        console.log(error)
      })

    

      $("#message-container").animate({
        scrollTop: $("#message-container").prop('scrollHeight')
      },1000);

  }

  
  return (
    <div className="chat_assistant small position-fixed bottom-0 end-0 rounded-top shadow">
      <div className="assistant_header rounded-top d-flex align-items-center justify-content-between gap-2 position-relative pointer px-2 pe-3" onClick={openChatAssistant}>
        <div className="assistant_img position-relative h-100" style={{ width: '100px' }}>
          <img src={require("../resources/images/meeting-girl.png")} alt="Sheela" className="img-fluid position-absolute bottom-0 start-0 w-100 object-fit-cover" style={{ height: '80px', objectPosition: 'top' }} />
        </div>
        <div className="assistant_name w-100 text-white text-capitalize text-start fs-6 fw-medium">Sheela</div>
        <button type="button" className="btn btn-sm btn-close btn-close-white opacity-100 shadow-none"></button>
      </div>
      {isOpen && (
        <div className="assistant_msgs_area d-flex flex-column flex-fill content">
          <div className="assistant_msg d-flex flex-column flex-fill overflow-auto px-3 pt-1 mb-2 messages" id="message-container" style={{ height: '310px' }}>
                        
            
          </div>
          <form onSubmit={handleSubmit}>
          <div className="assistant_input border-2 border-top border-secondary border-opacity-10">
            <div class="input-group bg-white">
              <input type="text" className="form-control border-0 rounded-0 shadow-none lh-sm py-3 userQuery" id="userQuery" placeholder="Ask anything to Sheela here..." autoComplete="off"/>
              <span class="input-group-text bg-transparent text-primary border-0 rounded-0" id="msgText1"><i type="submit" class="bi bi-send fs-5 pointer" title="Send"></i></span>
            </div>
          </div>
          </form>
        </div>
      )}
    </div>
  )
}
