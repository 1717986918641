const Actions = {
  SET_IS_ROOM_HOST: "SET_IS_ROOM_HOST",
  SET_IDENTITY: "SET_IDENTITY",
  SET_CONNECT_ONLY_WITH_AUDIO: "SET_CONNECT_ONLY_WITH_AUDIO",
  SET_ROOM_ID: "SET_ROOM_ID",
  SET_TWILIO_ACCESS_TOKEN: "SET_TWILIO_ACCESS_TOKEN",
  SET_PARTICIPANTS: "SET_PARTICIPANTS",
  SET_MESSAGES: "SET_MESSAGES",
  SET_SHOW_OVERLAY: "SET_SHOW_OVERLAY",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_ERROR: "LOGOUT_ERROR",
  SET_ADMIN_CALL: "SET_ADMIN_CALL",
  SET_FORM_ID: "SET_FORM_ID",
  SET_USER: "SET_USER",
  SET_BGIMAGE: "SET_BGIMAGE",
  IS_REMOVED_BACKGROUND: "IS_REMOVED_BACKGROUND",
  SET_BGIMAGE_PREV: "SET_BGIMAGE_PREV",
  USER_BREAK: "USER_BREAK",
  SET_CLIENTDATA: "SET_CLIENTDATA",
  SET_ROOM_SID: "SET_ROOM_SID",
  SET_IS_CLIENT_ON_CALL: "SET_IS_CLIENT_ON_CALL",
  SET_USER_ROLE: "SET_USER_ROLE",
  SET_TOKEN: "SET_TOKEN",

  SET_PARTICIPANTS_LIST: "SET_PARTICIPANTS_LIST"
};

export const setIdentity = (identity) => {
  return {
    type: Actions.SET_IDENTITY,
    identity,
  };
};

export const setIsRoomHost = (isRoomHost) => {
  return {
    type: Actions.SET_IS_ROOM_HOST,
    isRoomHost,
  };
};

export const setConnectOnlyWithAudio = (onlyWithAudio) => {
  return {
    type: Actions.SET_CONNECT_ONLY_WITH_AUDIO,
    onlyWithAudio,
  };
};

export const setRoomId = (roomId) => {
  return {
    type: Actions.SET_ROOM_ID,
    roomId,
  };
};

export const setFormId = (formId) => {
  return {
    type: Actions.SET_FORM_ID,
    formId,
  };
};
export const setUserInfo = (userInfo) => {
  return {
    type: Actions.SET_USER,
    userInfo,
  };
};
export const setTwilioAccessToken = (token) => {
  return {
    type: Actions.SET_TWILIO_ACCESS_TOKEN,
    token,
  };
};

export const setParticipants = (participants) => {
  return {
    type: Actions.SET_PARTICIPANTS,
    participants,
  };
};

export const setMessages = (messages) => {
  return {
    type: Actions.SET_MESSAGES,
    messages,
  };
};

export const setShowOverlay = (showOverlay) => {
  return {
    type: Actions.SET_SHOW_OVERLAY,
    showOverlay,
  };
};

//Join call without info to participant
export const joinAsAdmin = (isAdmin) => {
  return {
    type: Actions.SET_ADMIN_CALL,
    isAdmin,
  };
};

export const setLogin = (isLoggedIn) => {
  return {
    type: Actions.LOGIN_SUCCESS,
    isLoggedIn,
  };
};

export const setBackgroundImage = (bgImage) => {
  return {
    type: Actions.SET_BGIMAGE,
    bgImage,
  };
};

export const setBackgroundPreviewImage = (bgImagePrev) => {
  return {
    type: Actions.SET_BGIMAGE_PREV,
    bgImagePrev,
  };
};

export const setIsRemovedBackground = (isRemoved) => {
  return {
    type: Actions.IS_REMOVED_BACKGROUND,
    isRemoved,
  };
};
export const setBreakData = (userBreaks) => {
  return {
    type: Actions.USER_BREAK,
    userBreaks,
  };
};

export const setClientInfo = (clientInfo) => {
  return {
    type: Actions.SET_CLIENTDATA,
    clientInfo,
  };
};

export const setRoomSid = (roomSid) => {
  return { type: Actions.SET_ROOM_SID, roomSid };
};

export const setIsClientOnCall = (clientOnCall) => {
  return { type: Actions.SET_IS_CLIENT_ON_CALL, clientOnCall };
};

export const setUserRole = (userRole) => {
  return { type: Actions.SET_USER_ROLE, userRole };
};

export const setToken = (token) => {
  return { type: Actions.SET_TOKEN, token };
};

export const setParticipantsList = (participant) => {
  return { type: Actions.SET_PARTICIPANTS_LIST, participant };
};

export default Actions;
