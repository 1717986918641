import axios from 'axios';
import { environment } from '../environment';

import { isJwtExpired } from 'jwt-check-expiration';
import { getAuth } from '@firebase/auth';

export const callAxios = async(url, data, reqType) => {
    
    if (reqType === 'post') {
      const postData = await axios.post( url, data)
        .catch((error) => {
          console.log(error);
        });
      return postData;
    }

    if (reqType === 'get') {
        
        const getData = await axios.get( url)
          .catch((error) => {
            console.log(error);
          });
        return getData;
      }

  }

export const toLoCale = async (timezone, date, type, dayNumber) => {
    if (type === 'current') {
      const gmtCurrentDateTime = new Date(date).toLocaleString(undefined, {
        timeZone: timezone,
      });
      const localDateTimeArray = gmtCurrentDateTime.split(',')[0].split('/');
      const localDate =
        localDateTimeArray[2] +
        '-' +
        localDateTimeArray[0].padStart(2, '0') +
        '-' +
        localDateTimeArray[1].padStart(2, '0');

      return localDate;
    } 

     else if (type === 'datetime') {
      const gmtCurrentDateTime = new Date(date).toLocaleString(undefined, {
        timeZone: timezone,
      });
      const localDateTimeArray = gmtCurrentDateTime.split(',')[0].split('/');
      const localDate =
        localDateTimeArray[2] +
        '-' +
        localDateTimeArray[0].padStart(2, '0') +
        '-' +
        localDateTimeArray[1].padStart(2, '0') +
        ' ' +
        new Date(gmtCurrentDateTime).getHours().toString().padStart(2, '0') +
        ':' +
        new Date(gmtCurrentDateTime).getMinutes().toString().padStart(2, '0') +
        ':' +
        new Date(gmtCurrentDateTime).getSeconds().toString().padStart(2, '0');

      return localDate;
    } 
    else if (type === 'utc') {
      const localToUTC = new Date(date).toISOString().split('T');
      const localToUTCArray = localToUTC[0] + ' ' + localToUTC[1].split('.')[0];
      return localToUTCArray;
    } 
    else if (type === 'dayAgo') {
      const gmtCurrentDateTime = new Date().toLocaleString(undefined, {
        timeZone: timezone,
      });

      const prev = new Date(gmtCurrentDateTime).getDate() - dayNumber;
      const currentDateKorea = gmtCurrentDateTime.split(',')[0].split('/');
      const previousDate =
        currentDateKorea[2] +
        '-' +
        currentDateKorea[0].padStart(2, '0') +
        '-' +
        prev.toString().padStart(2, '0');
      return previousDate;
    } else if (type === 'plusDate') {
      const gmtCurrentDateTime = new Date().toLocaleString(undefined, {
        timeZone: timezone,
      });

      const prev = new Date(gmtCurrentDateTime).getDate() + dayNumber;
      const currentDateKorea = gmtCurrentDateTime.split(',')[0].split('/');
      const nextDate =
        currentDateKorea[2] +
        '-' +
        currentDateKorea[0].padStart(2, '0') +
        '-' +
        prev.toString().padStart(2, '0');
      return nextDate;
    } else {
      const gmtCurrentDateTime = new Date(date + ' UTC').toLocaleString(
        undefined,
        {
          timeZone: timezone,
        },
      );
      const localDateTimeArray = gmtCurrentDateTime.split(',')[0].split('/');
      const localDate =
        localDateTimeArray[2] +
        '-' +
        localDateTimeArray[0].padStart(2, '0') +
        '-' +
        localDateTimeArray[1].padStart(2, '0');

      return localDate;
    }
  }

  export const convertMilisecondsToTime = (seconds, nanoseconds)=>{
    const fireBaseTime = new Date(
      seconds * 1000 + nanoseconds / 1000000,
    );
    const atTime = fireBaseTime.toLocaleTimeString();
    return atTime;
  }

  export const convertMiliToHours = (loggedInTime, logoutTime) => {
  const ms = (logoutTime - loggedInTime);
  let seconds = Math.floor(ms/1000);

  let minutes = Math.floor(seconds/60);

  let hours = Math.floor(minutes/60);

  seconds = seconds % 60;

  minutes = minutes % 60;

  // hours = hours < 10 ? "0" + hours : hours;

  // minutes = minutes < 10 ? "0" + minutes : minutes;

  // seconds = seconds < 10 ? "0" + seconds : seconds;

  return `${hours}:${minutes}:${seconds}`;
}

 export const convertDateTimeFromIso = ( dateTime ) =>{
  
    var hours = new Date(dateTime).getHours();
    var minutes = new Date(dateTime).getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = new Date(dateTime).getDate() + ' ' + new Date(dateTime).toLocaleString('default', { month: 'long' }) + ' ' + new Date(dateTime).getFullYear() + ' ' +hours + ':' + minutes + ' ' + ampm;
    return strTime;
 }

 export const convertReadable = ( dateTime ) =>{
  //console.log(new Date(dateTime) +"IST")
  var strTime = new Date(dateTime).getDate() + ' ' + new Date(dateTime).toLocaleString('default', { month: 'short' }) + ' ' + new Date(dateTime).getFullYear();
  return strTime;
}

export const convertReadableTime = ( dateTime ) =>{
  
  var hours = new Date(dateTime).getHours();
  var minutes = new Date(dateTime).getMinutes();
  var seconds = new Date(dateTime).getSeconds();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  //var strTime = dateTime.split("T")[1].split(".")[0]+ ' ' + ampm;
  var strTime= hours +":"+minutes + " " +ampm;
  return strTime;
}

 export const getIndex = (List,roomId) =>{
  const index = List.findIndex(object => {
      return object.roomId === roomId;
    });

    return index;
}

export const getIndexByEmail = (List,email) =>{
  const index = List.findIndex(object => {
   // console.log(object.email.trim().toLowerCase())
      return object.email.trim().toLowerCase() === email;
    });

    return index;
}

export const getIndexByEmailOpportinites = (List,email) =>{
  const index = List.findIndex(object => {
      return object.emailaddress === email;
    });

    return index;
}

export const getIndexBySystemUserId = (List,systemuserid) =>{
  const index = List.findIndex(object => {
      return object.systemuserid === systemuserid;
    });

    return index;
}

export const convertFirebaseFormat = (createdAt) =>{
  const fireBaseTime = new Date(
    createdAt.seconds * 1000 + createdAt.nanoseconds / 1000000
  );
  const dateNew = fireBaseTime.toDateString();
  const atTime = fireBaseTime.toLocaleTimeString();
  return dateNew + " " + atTime;
}

export const timeDiffDaysHourMinSec = (date_future, date_now) => {
  //console.log("Utils::",date_future, date_now)
  let delta = Math.abs(date_future - date_now) / 1000;

    // calculate (and subtract) whole days
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;

    // calculate (and subtract) whole hours
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    // calculate (and subtract) whole minutes
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    // what's left is seconds
    const seconds = delta % 60;

    let dayText = days > 1? " Days": " Day";

    let showDays = days > 0? days + dayText:'';

    let hourText = hours>0? " Hours": " Hour";

    let showHours = hours > 0? hours + hourText:'';

    let minuteText = minutes>0? " Minutes": " Minute";

    let showMinutes = minutes > 0? minutes + minuteText:'';

    return showDays +' '+ showHours + ' ' + showMinutes + ' Ago';
}

export const getToken = (token) =>{
  const Token = token.split(".");
  return Token[1]
  // const decodedToken = atob(Token[1]);
  // return JSON.parse(decodedToken).exp;
}

export const callCrmApi = async(data) =>{
  const token = localStorage.getItem("idToken");
  //console.log(token)
    // if( isJwtExpired(token )){
    //   const auth1 = getAuth();
    //     auth1.currentUser.getIdToken(true)
    //         .then(async function(idToken) {
              
    //           localStorage.setItem("idToken",idToken);
              
          
    //         }).catch(function(error) {
            
    //         });
            
    // }
    const updatedToken = localStorage.getItem("idToken");
    //console.log(updatedToken)
  const config = {
    method: 'POST',
    url: environment.REACT_APP_CRM_LAMBDAURL_V1,
    // headers: {
    //   'Authorization': 'Bearer '+updatedToken,
    //   'Content-Type': 'application/json'
    // },
    data : data
  };


    const postData = await axios( config)
        .catch(async(error) => {
          console.log(error);
          
        });
      return postData;
    //return false;
}

export const convertSecondsToMinutes = (seconds) => {
  // console.log(seconds)
  const minutes = Math.floor(seconds / 60); // Get the number of full minutes
  const remainingSeconds = seconds % 60; // Get the remaining seconds

  // Pad minutes and seconds with 0 if less than 10
  const paddedMinutes = minutes.toString().padStart(2, "0") + "m";
  const paddedSeconds = remainingSeconds.toString().padStart(2, "0") + "s";

  return `${paddedMinutes} ${paddedSeconds}`;
}