import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import {Navigate, useLocation} from "react-router-dom"
import { database } from '../firebaseConfig';
import { environment } from '../environment';
import { collection, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';

const ProtectedRoute = ({children, page, email,userRoleArray}) => {
    //console.log(email)
   
    let location = useLocation();
    const userRoleStore = useSelector((state) => state.userRole);
    const userRole = userRoleStore? userRoleStore:localStorage.getItem("userRole");
     //console.log("Store userRole:",userRole)   
    // console.log(userRole,page)



    //console.log(userRoleArray,page)

    const marketingPages = ["marketingDashboard","clientQuries","marketingDetails"];
    const allRolePages = ["myactivecalls","leadlist", "profile", "issueslist","replieslist","meetingDetails"];
    const chartReport = ["CAM","SA","SSA"];
    const SASSA = ["SA","SSA"];
    const SASSAPAGES = ["ongoingcalls","memberlist","composemedia","accountdetails","blockedUsers","deletedUsers", "leadReport","test"];
    if(allRolePages.includes(page) && (userRole === "CAM" || userRole === "BDM" || userRole === "SA" || userRole === "SSA")) {
        return children;
    } 
    else if(page ==="reports" || page === "opportunityReport" && chartReport.includes(userRole)){
        return children;
    }
    else if(marketingPages.includes(page) && userRoleArray.includes("MAM") || userRole === "SA" || userRole === "SSA"){
        return children;
    }
    else if(SASSAPAGES.includes(page) && SASSA.includes(userRole)){
        return children;
    } 
    else if(page === "leaddescription" && (userRole === "BDM" || userRole === "SA" || userRole === "SSA")) {
        return children;
    }
    else if(page === "adminChats" && (userRole === "SSA")){
        return children;
    }
    else{
        // return <Navigate to="/unauthorized" state={{ from: location}} />
        return <Navigate to="/chats" state={{ from: location}} />
    }
    

};

export default ProtectedRoute;